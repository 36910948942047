@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222 84% 4.9%;

    --primary: 142.1 76.2% 36.3%;
    --primary-foreground: 355.7 100% 97.3%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 142.1 76.2% 36.3%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --card: 222 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --ring: 142.1 70.6% 45.3%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    overflow-x: hidden;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary text-primary-foreground hover:bg-primary/90 transition-colors duration-200;
  }

  .btn-secondary {
    @apply bg-secondary text-secondary-foreground hover:bg-secondary/80 transition-colors duration-200;
  }

  .card {
    @apply bg-card text-card-foreground border border-border shadow-sm;
  }

  .input {
    @apply bg-input text-foreground border border-border focus:border-primary focus:ring-1 focus:ring-primary transition-colors duration-200;
  }
}

@layer utilities {
  .text-gradient {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/60;
  }
}

/* Autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--foreground) !important;
}

@layer base {
  :root {
    --sidebar-background: 0 0% 100%;
    --sidebar-foreground: 240 10% 3.9%;
    --sidebar-primary: 142.1 76.2% 36.3%;
    --sidebar-primary-foreground: 355.7 100% 97.3%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 240 5.9% 90%;
    --sidebar-ring: 142.1 76.2% 36.3%;
  }

  .dark {
    --sidebar-background: 20 14.3% 4.1%;
    --sidebar-foreground: 0 0% 95%;
    --sidebar-primary: 142.1 70.6% 45.3%;
    --sidebar-primary-foreground: 144.9 80.4% 10%;
    --sidebar-accent: 12 6.5% 15.1%;
    --sidebar-accent-foreground: 0 0% 98%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 142.1 70.6% 45.3%;
  }
}

html, body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}

body {
  touch-action: pan-y;
}
